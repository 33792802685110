import { Heading, Box, Stack, Text, Flex } from '@chakra-ui/react'
import { LinkButton } from 'components/ui'

const Component = () => {
  return (
    <Flex flex={1}>
      <Stack textAlign="center" height="100%" width="100%" spacing={4} justifyContent="center">
        <Heading size="lg">404 Not Found</Heading>
        <Text color="deepgray">ページが見つかりません</Text>
        <Box>
          <LinkButton colorScheme="blue" variant="solid" href="/">
            TOP
          </LinkButton>
        </Box>
      </Stack>
    </Flex>
  )
}

export default Component
